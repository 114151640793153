import React, {useState} from 'react';
import contactStyles from "./contact.module.css"
import {useForm} from 'react-hook-form';
import useSiteMetadata from "@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-site-metadata";

const required = "This field is required";


export default () => {
  const {apiPath} = useSiteMetadata();
  const [submitted, setSubmitted] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    errors,
    reset,
    formState: {isSubmitting}
  } = useForm();

  const onSubmit = async data => {
    try {
      await fetch(apiPath, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        body: JSON.stringify(data),
        headers: {
          "Content-type": "application/json; charset=UTF-8"
        }
      });
      setSubmitted(true);
      reset();
    } catch (error) {
      setError(
        "submit",
        "submitError",
        `Oops! There seems to be an issue! ${error.message}`
      );
    }
  };

  const showSubmitError = msg => <p className={contactStyles.msgError}>{msg}</p>;

  const showThankYou = (
    <div className={contactStyles.row}>
      <div className={contactStyles.col50}>
        Thank you! Your message was sent.
      </div>
      <div className={contactStyles.col50}>
        <button type="button" onClick={() => setSubmitted(false)}>
          Send another message?
        </button>
      </div>
    </div>
  );


  const showForm = (
    <form onSubmit={handleSubmit(onSubmit)} method="post">

      <div className={contactStyles.row}>
        <div className={contactStyles.col25}>
          <label htmlFor="name">Name</label>
        </div>
        <div className={contactStyles.col75}>
          <input type="text" id="name" name="name" placeholder="Your name..."
                 ref={register({required})}
                 disabled={isSubmitting}
          />
          {errors.name && <div className={contactStyles.msgError}>{errors.name.message}</div>}
        </div>
      </div>

      <div className={contactStyles.row}>
        <div className={contactStyles.col25}>
          <label htmlFor="email">Email</label>
        </div>
        <div className={contactStyles.col75}>
          <input type="text" id="email" name="email" placeholder="Your email..."
                 ref={
                   register({
                     required,
                     pattern: {
                       value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                       message: 'Please enter a valid e-mail address'
                     }
                   })
                 }
                 disabled={isSubmitting}
          />
          {errors.email && <div className={contactStyles.msgError}>{errors.email.message}</div>}
        </div>
      </div>

      <div className={contactStyles.row}>
        <div className={contactStyles.col25}>
          <label htmlFor="subject">Subject</label>
        </div>
        <div className={contactStyles.col75}>
          <input type="text" id="subject" name="subject"
                 placeholder="AWS Solutions Architecture, AI enabled Product Development,..."
                 ref={register({required})}
                 disabled={isSubmitting}
          />
          {errors.subject && <div className={contactStyles.msgError}>{errors.subject.message}</div>}
        </div>
      </div>
      <div className={contactStyles.row}>
        <div className={contactStyles.col25}>
          <label htmlFor="message">Message</label>
        </div>
        <div className={contactStyles.col75}>
                     <textarea id="message" name="message" placeholder=" .... "
                               className={contactStyles.textArea}
                               ref={register({required})}
                               disabled={isSubmitting}
                     />
          {errors.message && <div className={contactStyles.msgError}>{errors.message.message}</div>}
        </div>
      </div>

      <div className={contactStyles.row}>
        <input type="submit" value="Submit" disabled={isSubmitting}/>
      </div>

    </form>
  );

  return (
    <div className="page contact-page">
      <div className="text-side">
        {errors && errors.submit && showSubmitError(errors.submit.message)}
      </div>
      <div className={contactStyles.container}>{submitted ? showThankYou : showForm}</div>
    </div>
  );


}

